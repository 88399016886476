<template>
  <div id="screenSize" class="screen-size flex-center-column">
    <div class="login-form c-border">
      <div class="flex-center-column">
        <img src="@/assets/img/logo_transparent_background_horizontal@2x.png" alt>
      </div>
      <b-form @submit="login">
        <b-form-group id="input-group-2" class="mt-60" label="用户名" label-for="input-2">
          <b-form-input id="input-2" v-model="form.username" required placeholder="输入账号" />
        </b-form-group>

        <b-form-group id="input-group-1" label="密码" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.password"
            type="password"
            required
            placeholder="输入密码"
          />
        </b-form-group>
        <div class="flex-center-column mt-60">
          <button type="submit">登录</button>
          <div class="flex-center mt-30">
            <!-- <button @click="gotohome()">abc</button> -->
            <router-link to="/" style="color:#666">返回主页</router-link>
            <div style="color:#666; padding:0 10px">|</div>
            <router-link to="/register" style="color:#666">注册</router-link>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      form: {
        password: '',
        username: ''
      }
    }
  },

  computed: {
    ...mapState({
      loginStatus: state => state.login.loginStatus,
      userName: state => state.login.userName
    })
  },

  mounted() {
    this.getContainerHeight()
  },

  methods: {
    ...mapActions({
      fetchBrokerInfo: 'login/fetchBrokerInfo'
    }),
    login: function(e) {
      e.preventDefault()
      const loginInfo = {
        username: this.form.username,
        password: this.form.password
      }
      this.$store.dispatch('login/loginAction', loginInfo).then(() => {
        const userInfo = {
          nativeCode: 'imAinvestcnabc',
          username: this.form.username
        }
        localStorage.setItem('username', this.form.username)
        if (this.loginStatus) {
          this.$router.push('user-account')
          this.fetchBrokerInfo(userInfo).then((res) => {
            console.log('then this.brokerInfoData', this.brokerInfoData)
          })
        }
      })
    },

    getContainerHeight: function() {
      const screenHeight = document.getElementById('screenSize')
      const pageHeight = window.innerHeight
      screenHeight.style.height = pageHeight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
// .screen-size {
//   height: 100%;
// }

.login-form {
  width: 450px;
  padding: 30px 60px;
  border-radius: 5px;

  img {
    width: 50%;
    text-align: center;
  }
}

button {
  width: 100%;
  height: 38px;
  border: 1px solid #ff3333ff;
  background-color: #fff;
  color: #ff3333ff;
  border-radius:4px;
  font-size: 20px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

button:hover {
  border: 0;
  background: rgba(255, 51, 51, 1);
  color: #fff;
}
</style>
